import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/static-page.js";
import { BookList } from "../components/booklist/booklist";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Reading`}</h1>
    <p>{`This is my ongoing reading list of books and longer-form articles. I don’t include anything I wouldn’t recommend. Of course, that doesn’t mean everything here is for you. Any books or articles I especially loved get a gold star.`}</p>
    <BookList mdxType="BookList" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      